import React, {useCallback, useEffect, useMemo, useState} from 'react';

import { useLazyQuery } from "@apollo/client";
import {CustomPaging, PagingState} from "@devexpress/dx-react-grid";
import {Grid, PagingPanel, TableHeaderRow, VirtualTable} from "@devexpress/dx-react-grid-material-ui";
import {Box, Typography, withStyles} from "@material-ui/core";
import { navigate, useLocation } from "@reach/router";
import { identity, pickBy } from "lodash";
import qs from "querystringify";

import HelpTooltip from 'components/HelpTooltip';

import {
  COLUMN_EXTENSIONS_STRUCTURE_ACCEPTANCE_TO_WAREHOUSE,
  COLUMNS_MODEL_CREATOR_ACCEPTANCE_TO_WAREHOUSE,
  TABS_ACCEPTANCE
} from "./constants";
import { GET_STORAGE_ACCEPTANCES } from "./graphql/queries/storageAcceptances";
import ErrorAlert from "../../components/ErrorAlert";
import Tabs from "../../components/Tabs";
import { GridRoot } from "../deals/components/helpers";
import { PAGING_PANEL_MESSAGES } from "../shop/constants";

export default function PageGoods() {
  const stylesTabs = useMemo(() => ({
    wrapperTabs: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #E7E8EA',
      textAlign: 'center',
      fontSize: '14px',
      boxShadow: '0 9px 13px rgb(13 29 50 / 12%)',
      padding: {
        xs: '0 16px',
        lg: '0 24px'
      },
      maxWidth: '100%',

      '& > div > div': {
        overflowX: 'scroll !important'
      }
    },
    tab: {
      minWidth: 'auto !important',
      width: 'auto !important',
      color: '#0D1D32',
      fontSize: '14px',
      fontWeight: 500,
    },
  }), []);
  const styles = useMemo(() => ({
    wrapperTable: {
      margin: {
        xs: '0px',
        lg: '24px'
      },
      border: '1px solid #E7E8EA',
      height: "100%",
      borderRadius: '8px',
      marginBottom: '60px',

      '& .MuiTableHead-root tr': {
        backgroundColor: '#F5F6F7 !important',

        '& span': {
          color: '#6E7884',
        },
      },
      '& table': {
        marginBottom: '0 !important',

        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root:hover path': {
          color: '#1771e6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root path': {
          color: '#CFD2D6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .Mui-checked path': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root:hover': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root': {
          color: '#CFD2D6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .Mui-checked': {
          color: '#1771e6'
        },
        '& tr:hover': {
          background: 'rgba(61, 108, 231, 0.05)',
        },
        '& th:last-child': {
          paddingRight: '16px',
        },
        '& td:last-child': {
          paddingRight: '16px',
        },
      },
      '& .CustomPaging': {
        marginTop: '16px',
        marginBottom: '16px',
      },
      '& .CustomPaging > div:nth-child(1)': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-child(2) > span': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-of-type(2) .MuiButton-text': {
        color: '#6E7884',
        width: '46px',
        height: '46px',
        margin: '0 1px',
        borderRadius: '50%',
      },
      '& .CustomPaging > div:nth-of-type(2) button:hover': {
        backgroundColor: '#3D6CE7 !important',
        color: '#ffffff',
      },
      '& .CustomPaging > div:nth-of-type(2) button[tabindex="-1"]:not([disabled])': {
        backgroundColor: '#3D6CE7',
        color: '#ffffff',
      },
    },
    pageTitleContainer: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      padding: {
        xs: '0 16px',
        lg: '0 24px'
      },
      backgroundColor: '#ffffff',
      height: {
        xs: 'auto',
        lg: '100px'
      },
      minHeight: {
        xs: '64px',
        lg: '100px'
      }
    },
    pageTitle: {
      fontSize: {
        xs: '24px !important',
        lg: '32px !important'
      },
      margin: '0 !important',
      // width: '460px',
      fontWeight: 700,
    },
    wrapperContent: {
      margin: {
        xs: '24px 16px',
        lg: '24px'
      },
      background: '#ffffff',
      boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.1)',
      borderRadius: '16px',
      height: 'calc(100% - 210px)',
      padding: '24px',
    },
  }), []);

  const MyPager = ({ ...restProps }) => {
    return <PagingPanel.Container
      {...restProps}
      className={`CustomPaging`}
    />;
  };

  const MyPagerComponent = withStyles({ name: "MyPager" })(
    MyPager
  );

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const location = useLocation();

  const { activeTab, currentPage} = useMemo(() => {
    const query = qs.parse(location.search);

    return {
      activeTab: query.tab ? Number(query.tab) : 0,
      currentPage: query.page ? Number(query.page) : 1,
    };
  }, [location.search]);

  const [
    fetchStorageAcceptances,
    {
      data: dataStorageAcceptances,
      error: errorStorageAcceptances,
      loading: loadingStorageAcceptances
    }
  ] = useLazyQuery(GET_STORAGE_ACCEPTANCES);

  const [countTabs, setCountTabs] = useState({});

  useEffect(() => {
    let tab;

    switch (activeTab) {
      case 0:
        tab = 'READY_FOR_ACCEPTANCE';
        break;
      case 1:
        tab = 'ACCEPTANCE_STARTED';
        break;
      case 2:
        tab = 'ACCEPTED';
        break;

      default:
        tab = 'READY_FOR_ACCEPTANCE';
    }

    let filter = {
      statusEq: tab
    }

    fetchStorageAcceptances({
      variables: {
        "page": currentPage,
        "limit": 20,
        filter
      }
    })
  }, [fetchStorageAcceptances, currentPage, activeTab]);

  useEffect(() => {
    let counts = {};
    TABS_ACCEPTANCE.forEach(tab => {
      counts[tab] = 0;
    });

    if (dataStorageAcceptances?.storageAcceptances?.tabs) {
      dataStorageAcceptances.storageAcceptances.tabs.forEach(tab => {
        counts[tab.status] = tab?.count || 0;
      })
    }

    setCountTabs(counts);

    if (dataStorageAcceptances?.storageAcceptances?.storageAcceptances?.collection) {
      setRows(dataStorageAcceptances?.storageAcceptances.storageAcceptances.collection);
    }

    setTotalCount(prev => {
      return dataStorageAcceptances?.storageAcceptances?.storageAcceptances?.metadata?.totalCount || prev
    })
  }, [dataStorageAcceptances]);

  const updateSearchLocation = useCallback(
    (params) => {
      const parsedQuery = qs.parse(location.search);
      const query = pickBy({ ...parsedQuery, ...params }, identity);

      if (query?.auth_token) {
        delete query.auth_token;
      }


      const search = qs.stringify(query, true);
      navigate(search ? search : location.pathname);
    },
    [location],
  );

  const toggleTabs = useCallback(
    (activeTab) => {
      updateSearchLocation({
        tab: activeTab,
        page: 1
      });
    },
    [updateSearchLocation],
  );

  const handleCurrentPageChange =useCallback(
    (page) => {
      updateSearchLocation({ page: page + 1 });
    },
    [updateSearchLocation],
  );

  if (errorStorageAcceptances) {
    return <ErrorAlert message={errorStorageAcceptances.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <Box sx={{ paddingBottom: '20px', height: '100%', overflow: 'auto', }}>
      <Box sx={ styles.pageTitleContainer }>
        <Typography gutterBottom sx={ styles.pageTitle } variant="h2">
          Приемка на склад
        </Typography>
        <HelpTooltip title={(<a rel="noreferrer" style={{color: '#fff', fontSize: '14px'}} target='_blank' href={`${process.env.REACT_APP_HOST}/wiki_page/222`}>Перейти к описанию</a>)} />
      </Box>

      <Tabs
        counterTabs={[countTabs["READY_FOR_ACCEPTANCE"], countTabs["ACCEPTANCE_STARTED"], countTabs["ACCEPTED"]]}
        defaultActiveTab={activeTab}
        onChange={toggleTabs}
        sx={ stylesTabs }
        tabs={[ 'Готовы к приемке', 'В работе', 'Приняты на склад' ]}
      />

      <Box sx={ styles.wrapperContent }>
        <Box sx={styles.wrapperTable}>
          <Grid
            columns={COLUMNS_MODEL_CREATOR_ACCEPTANCE_TO_WAREHOUSE}
            getRowId={(row) => row.id}
            rootComponent={GridRoot}
            rows={rows}
          >
            <VirtualTable
              cellComponent={(props) => (
                <VirtualTable.Cell className="OrdersTableCell" {...props} />
              )}
              columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_ACCEPTANCE_TO_WAREHOUSE}
              messages={{ noData: loadingStorageAcceptances ? 'Загрузка...' : 'Нет данных' }}
            />

            <PagingState
              currentPage={currentPage - 1}
              defaultCurrentPage={currentPage - 1}
              defaultPageSize={20}
              onCurrentPageChange={handleCurrentPageChange}
            />

            <CustomPaging totalCount={totalCount} />

            <TableHeaderRow />

            <PagingPanel containerComponent={MyPagerComponent} messages={PAGING_PANEL_MESSAGES} pageSizes={[ 10, 50, 0 ]} />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}