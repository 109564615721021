import React, { useCallback, useState } from 'react';

import { Box, Typography } from '@material-ui/core';

import HelpTooltip from 'components/HelpTooltip';

import DocumentsFilter from './components/DocumentsFilter';
import DocumentsTable from './components/DocumentsTable';
import DocumentStatusBar from './components/DocumentStatusBar';
import AzDropzone from './components/Dropzone/AzDropzone';
import FilesModal from './components/FilesModal';
import IncomingDocumentsToolbar from './components/IncomingDocumentsToolbar';
import { client } from '../../core/ApolloWrapper';

export default function IncomingDocuments() {
  const [selection, setSelection] = useState([]);
  const [files, setFiles] = useState([]);
  const [total, setToolbarTotal] = useState(0);
  const [fetchPolicy, setFetchPolicy] = useState('cache-first');

  const refetchData = useCallback(
    () => {
      client.refetchQueries({ include: 'active' });
      setFetchPolicy('no-cache');
    },
    [],
  );

  const [canGlobDrag, setCanGlobDrag] = useState(true);

  return (
    <Box
      sx={{
        mb: 8,
        p: 3,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 3, gap: '8px' }}>
        <Typography gutterBottom variant="h2">
          Входящие документы
        </Typography>

        <HelpTooltip title={(<a rel="noreferrer" style={{color: '#fff', fontSize: '14px'}} target='_blank' href={`${process.env.REACT_APP_HOST}/wiki_page/256`}>Перейти к описанию</a>)} />

        <Box sx={{ marginLeft: "16px" }}>
          <Typography component={"span"} sx={{ color: "red" }}>NEW:</Typography>
          {" "}
          <Typography component={"span"}>Новые типы документов теперь доступны к разнесению</Typography>
        </Box>
      </Box>

      <AzDropzone
        {...{ canGlobDrag }}
        onFilesDropped={setFiles}
        title={
        <>
          <Typography>Документы, доступные к разнесению: отгрузочные документы, дела (Кассационная инстанция, Апелляционная инстанция, Решение/СА, Исполнительный лист)</Typography>
          <Typography>Перетяните или нажмите на эту область</Typography>
        </>
        }
      />
      <FilesModal {...{ files, refetchData }} />

      <IncomingDocumentsToolbar
        {...{ refetchData, selection, setSelection, total }}
      />

      <DocumentStatusBar />

      <Box sx={{ flex: 0.999, minHeight: 0 }}>
        <Box
          sx={{
            border: '8px solid whitesmoke',
            borderRadius: '8px',
            borderTopLeftRadius: 0,
            height: 'calc(100% - 36px)',
          }}
        >
          <Box height={1} px={3} py={2}>
            <DocumentsFilter />
            <DocumentsTable
              {...{
                fetchPolicy,
                selection,
                setCanGlobDrag,
                setSelection,
                setToolbarTotal,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}